import React, { FC, useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Card, Col, Empty, Modal, Result, Row, Tabs } from 'antd';
import ContentCard from '../../../../containers/Layout/Sidebar/Content/Card';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import routes from '../../../../constants/routes';
import { newsFetchAction, removeNewsAction } from '../actions/news';
import { useDispatch, useSelector } from 'react-redux';
import { baseModelSelector } from '../../../../selectors/common';
import { ReloadOutlined } from '@ant-design/icons/lib';
import { makePath } from '../../../../core/utils/makePath';
import { INewsState } from '../types/state';
import { useTitle } from '../../../../hooks/common/useTitle';

const { TabPane } = Tabs;
const { Meta } = Card;

const NewsPage: FC<RouteComponentProps> = ({ history }) => {
  useTitle('Find Express - Xəbərlər');

  const dispatch = useDispatch();
  const { loading, data, error } = useSelector(baseModelSelector<INewsState>('news'));

  const gotoCreate = useCallback(() => {
    history.push(routes.news.create);
  }, [history]);

  const handleFetch = useCallback(() => {
    dispatch(newsFetchAction());
  }, [dispatch]);

  const gotoEdit = useCallback(
    (id) => {
      history.push(makePath(routes.news.edit, { id }));
    },
    [history],
  );

  const handleDelete = useCallback(
    (id) => {
      const action = () => removeNewsAction([id])(dispatch).catch(() => null);

      Modal.confirm({
        title: 'Xəbəri silməyə əminsinizmi?',
        content: 'Silməni təsdiqlədikdən sonra xəbərin geri qaytarılması mümkün olmayacaq.',
        onOk: action,
      });
    },
    [dispatch],
  );

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  return (
    <ContentCard className='shops-page content-card-scrollable'>
      {loading && (
        <Row gutter={[16, 16]}>
          {new Array(20).fill(null).map((_, index) => {
            return (
              <Col key={index} span={6}>
                <Card cover={<div style={{ height: 203, width: '100%' }} />} loading={true} actions={[<EditOutlined key='edit-action' />, <DeleteOutlined key='delete-action' />]} />
              </Col>
            );
          })}
        </Row>
      )}
      {!loading && !error && (
        <Tabs
          className='shops-page-tab'
          tabBarExtraContent={
            <Button type='primary' onClick={gotoCreate} icon={<PlusCircleOutlined />}>
              Yeni
            </Button>
          }
        >
          <TabPane tab='Xəbərlər'>
            <Row gutter={[16, 16]}>
              {!data.length && (
                <div className='absolute-empty'>
                  <Empty description={false} />
                </div>
              )}
              {data.map((news) => {
                return (
                  <Col key={news.id} span={6}>
                    <Card
                      className='shops-page-card'
                      actions={[<EditOutlined key='edit-action' onClick={() => gotoEdit(news.id)} />, <DeleteOutlined onClick={() => handleDelete(news.id)} key='delete-action' />]}
                      hoverable
                      cover={<img alt={news.title} src={news.image.replace('api2', 'api')} />}
                    >
                      <Meta title={news.title} description={news.url} />
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </TabPane>
        </Tabs>
      )}
      {!loading && error && (
        <Result
          className='absolute-result'
          extra={
            <Button onClick={handleFetch} type='primary' icon={<ReloadOutlined />}>
              Yenilə
            </Button>
          }
          title='Məlumatlar əldə edilə bilmədi'
          status='warning'
        />
      )}
    </ContentCard>
  );
};

export default React.memo(NewsPage);
